import React, { useState, useEffect } from "react"
import css from "./AddGroupContenModal.module.scss"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"
import TimezoneSetting from "../TimezoneSetting"
import SearchOrgs from "./OrgsSearchContent/SearchOrgs"

type AddGroupContentModalProps = {
  onClose: () => void
  clinicid: number
  refreshClinicData: () => void
}

const AddGroupContentModal: React.FC<AddGroupContentModalProps> = ({
  onClose,
  clinicid: clinicid,
  refreshClinicData,
}) => {
  const [selectedSettings, setSelectedSettings] = useState<
    Record<string, number>
  >({})
  const [defaultClinicSettings, setDefaultClinicSettings] = useState<
    { setting_type: string; setting_text: string }[]
  >([])
  const [editedTimezone, setEditedTimezone] = useState<string>("")
  const [daylightSavings, setDaylightSavings] = useState<boolean>(false)
  const [showDefaultSettingsMessage, setShowDefaultSettingsMessage] =
    useState(false)
  const [addAsScheduler, setAddAsScheduler] = useState(false)
  const [groupName, setGroupName] = useState("")
  const [groupAbbrev, setGroupAbbrev] = useState("")
  const [formError, setFormError] = useState(false)
  const [step, setStep] = useState<"settings" | "organizations">("settings")
  const [updatedSettings, setUpdatedSettings] = useState<
    Record<string, string | number | boolean>
  >({})
  const [selectedOrgs, setSelectedOrgs] = useState<TigerconnectGroupSetting[]>(
    []
  )

  const settingsData = [
    {
      title: "Feature Settings",
      options: [
        { key: "batch_import_export", label: "Batch Import / Export" },
        { key: "calendar_v2", label: "Calendar 2.0" },
        { key: "multicalendar_report", label: "Multi-calendar report" },
        {
          key: "scheduler_edit_group_merged_daily",
          label: "MDV Settings - Edit my Groups protocols/Timezone/Job Times",
        },
        { key: "split_shift", label: "Split shift" },
        {
          key: "always_track_splitshift",
          label: "Always track split shift changes",
        },
        {
          key: "scheduling_engine_rules",
          label: "Scheduling Engine + Rules",
        },
        { key: "casting_jobs", label: "Allow display of empty assignments" },
        { key: "rules_only", label: "Rules Only" },
        { key: "draft_mode", label: "Draft mode" },
        {
          key: "multiple_assignments",
          label: "Allow multiple assignments for one job",
        },
      ],
    },
    {
      title: "TigerConnect Organization Sync Settings",
      options: [
        {
          key: "tigerconnect_one_to_multiple_roles_sync",
          label: "Enable sync from one job to multiple roles",
        },
        {
          key: "scheduling_gap_notifications",
          label: "Notify schedulers of empty TigerConnect Roles",
        },
      ],
    },
  ]

  useEffect(() => {
    const fetchDefaultSettings = async () => {
      try {
        const response = await api.getDefaultsClinicSettings(clinicid)
        if (!response || response.length === 0) {
          setShowDefaultSettingsMessage(true)
        } else {
          setDefaultClinicSettings(response)
          setShowDefaultSettingsMessage(false)
        }
      } catch (error) {
        handleApiError(error as PlainObjectType)
      }
    }

    fetchDefaultSettings()
  }, [clinicid])

  useEffect(() => {
    if (defaultClinicSettings.length > 0) {
      const initialSettings = defaultClinicSettings.reduce(
        (acc: Record<string, number>, setting) => {
          acc[setting.setting_type] = parseInt(setting.setting_text, 10)
          return acc
        },
        {}
      )
      initialSettings["calendar_v2"] = 1

      const existingTimezone =
        defaultClinicSettings.find(
          (setting) => setting.setting_type === "timezone"
        )?.setting_text || "0"
      const existingDaylightSavings = defaultClinicSettings.find(
        (setting) => setting.setting_type === "daylight_savings"
      )?.setting_text

      setEditedTimezone(existingTimezone)
      if (existingDaylightSavings)
        setDaylightSavings(existingDaylightSavings === "1")

      setSelectedSettings(initialSettings)
    }
  }, [defaultClinicSettings])

  const handleShowOrgs = () => {
    if (!groupName.trim() || !groupAbbrev.trim()) {
      setFormError(true)
      return
    }
    setFormError(false)
    const settingsToSave = {
      name: groupName,
      abbrev: groupAbbrev,
      timezone: editedTimezone,
      daylight_savings: daylightSavings ? "1" : "0",
      add_as_scheduler: addAsScheduler,
      ...Object.entries(selectedSettings).reduce((acc, [key, value]) => {
        acc[key] = value.toString() === "NaN" ? "0" : value.toString()
        return acc
      }, {} as Record<string, string>),
    }
    setUpdatedSettings(settingsToSave)
    setStep("organizations")
  }

  const handleChange = (key: string, value: string | number | boolean) => {
    const formattedValue =
      typeof value === "boolean" ? (value ? "1" : "0") : value

    setUpdatedSettings((prev) => ({
      ...prev,
      [key]: key === "calendar_v2" ? "1" : formattedValue,
    }))

    switch (key) {
      case "name":
        setGroupName(value as string)
        break
      case "abbrev":
        setGroupAbbrev(value as string)
        break
      case "timezone":
        setEditedTimezone(value as string)
        break
      case "daylight_savings":
        setDaylightSavings(value === "1")
        break
      case "add_as_scheduler":
        setAddAsScheduler(value as boolean)
        break
      default:
        if (typeof value === "number") {
          setSelectedSettings((prev) => ({
            ...prev,
            [key]: key === "calendar_v2" ? 1 : value,
          }))
        }
        break
    }
  }

  const handleSaveSettings = async () => {
    try {
      const newGroup = await api.createGroup({
        clinicid,
        name: groupName,
        abbrev: groupAbbrev,
        timezone: editedTimezone,
        daylight_savings: daylightSavings,
        add_as_scheduler: addAsScheduler,
        priority: 1,
      })

      if (newGroup?.groupid) {
        const formattedSettings = transformSettingsForGroup(
          defaultClinicSettings
        )
        formattedSettings["groupid"] = newGroup.groupid.toString()

        // Ensure no NaN values in updatedSettings and preserve timezone
        const { timezone, ...otherSettings } = updatedSettings
        const cleanUpdatedSettings = Object.entries(otherSettings).reduce(
          (acc, [key, value]) => {
            acc[key] =
              value === "NaN" || String(value) === "NaN"
                ? "0"
                : value.toString()
            return acc
          },
          {} as Record<string, string>
        )

        await api.updateGroupSettings(newGroup.groupid, {
          ...cleanUpdatedSettings,
          ...formattedSettings,
        })

        if (selectedOrgs.length > 0) {
          await api.createTigerconnectSettings(
            selectedOrgs.map((org) => ({
              ...org,
              groupid: newGroup.groupid,
              tigerconnect_key:
                org.tigerconnect_key === "NaN" || org.tigerconnect_key === null
                  ? ""
                  : org.tigerconnect_key,
              tigerconnect_secret:
                org.tigerconnect_secret === "NaN"
                  ? "0"
                  : org.tigerconnect_secret,
            }))
          )
        }
      }

      refreshClinicData()
      onClose()
    } catch (error: any) {
      const message =
        error?.response?.data?.message ||
        error?.data?.response?.data?.message ||
        "An unexpected error occurred"
      handleApiError({ fail: true, message })
    }
  }

  if (showDefaultSettingsMessage) {
    return (
      <div className={css.tabContent}>
        <p className={css.warningMessage}>
          Default group settings must be created before adding a new group.
        </p>
        <div className={css.buttonContainer}>
          <button className={css.cancelSettings} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    )
  } else {
    if (formError) {
      return (
        <p className={css.errorMessage}>
          Group Name, Group Abbreviation and Timezone are required.
        </p>
      )
    }
    return (
      <div className={css.tabContent}>
        {step === "settings" ? (
          <>
            <div className={css.groupNameContent}>
              <div>
                <h3 className={css.label}>Group Name</h3>
                <input
                  className={css.groupNameInput}
                  type="text"
                  maxLength={80}
                  value={groupName}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </div>
              <div>
                <h3 className={css.label}>Group Abbreviation</h3>
                <input
                  className={css.groupNameInput}
                  type="text"
                  maxLength={20}
                  value={groupAbbrev}
                  onChange={(e) => handleChange("abbrev", e.target.value)}
                />
              </div>
            </div>
            <div className={css.timezoneContent}>
              <h3>Timezone</h3>
              <div className={css.timezoneSetting}>
                <TimezoneSetting
                  timezone={editedTimezone}
                  onChange={(value) => handleChange("timezone", value)}
                />
                <label className={css.optionLabel}>
                  <input
                    type="checkbox"
                    className={css.checkbox}
                    checked={daylightSavings}
                    onChange={(e) =>
                      handleChange("daylight_savings", e.target.checked)
                    }
                  />
                  DST
                </label>
              </div>
              <div>
                <div className={css.schedulerCheckbox}>
                  <label className={css.optionLabel}>
                    <input
                      type="checkbox"
                      className={css.checkbox}
                      checked={addAsScheduler}
                      onChange={(e) =>
                        handleChange("add_as_scheduler", e.target.checked)
                      }
                    />
                    Add me as the scheduler for this group
                  </label>
                </div>
              </div>
            </div>
            <div className={css.content}>
              <div className={css.settingsContent}>
                <div className={css.settingsContent}>
                  {settingsData.map((section, sectionIndex) => {
                    if (section.title === "Feature Settings") {
                      const firstColumnOptions = section.options.filter(
                        (option) =>
                          ![
                            "calendar_v2",
                            "scheduler_edit_group_merged_daily",
                            "scheduling_engine_rules",
                            "rules_only",
                          ].includes(option.key)
                      )
                      const secondColumnOptions = section.options.filter(
                        (option) =>
                          [
                            "calendar_v2",
                            "scheduler_edit_group_merged_daily",
                            "scheduling_engine_rules",
                            "rules_only",
                          ].includes(option.key)
                      )
                      return (
                        <div key={sectionIndex} className={css.section}>
                          <h3>{section.title}</h3>
                          <div className={css.optionsGridTwoColumns}>
                            <div className={css.column}>
                              {firstColumnOptions.map((option) => (
                                <label
                                  key={option.key}
                                  className={css.optionLabel}
                                >
                                  <input
                                    type="checkbox"
                                    className={css.checkbox}
                                    checked={selectedSettings[option.key] === 1}
                                    onChange={(e) =>
                                      handleChange(
                                        option.key,
                                        e.target.checked ? 1 : 0
                                      )
                                    }
                                  />{" "}
                                  {option.label}
                                </label>
                              ))}
                            </div>
                            <div className={css.column}>
                              {secondColumnOptions.map((option, index) => (
                                <React.Fragment key={option.key}>
                                  {index === 2 && (
                                    <p className={css.subtitle}>
                                      <em>
                                        DON'T CHECK BOTH OF THE FOLLOWING:
                                      </em>
                                    </p>
                                  )}
                                  <label className={css.optionLabel}>
                                    <input
                                      type="checkbox"
                                      className={css.checkbox}
                                      checked={
                                        selectedSettings[option.key] === 1
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          option.key,
                                          e.target.checked ? 1 : 0
                                        )
                                      }
                                    />{" "}
                                    {option.label}
                                  </label>
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      )
                    }
                    return (
                      <div key={sectionIndex} className={css.section}>
                        <h3>{section.title}</h3>
                        <div className={css.optionsColumn}>
                          {section.options.map((option) => (
                            <label key={option.key} className={css.optionLabel}>
                              <input
                                type="checkbox"
                                className={css.checkbox}
                                checked={selectedSettings[option.key] === 1}
                                onChange={(e) =>
                                  handleChange(
                                    option.key,
                                    e.target.checked ? 1 : 0
                                  )
                                }
                              />{" "}
                              {option.label}
                            </label>
                          ))}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={css.buttonContainer}>
              <button
                type="button"
                className={css.cancelSettings}
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className={css.saveSettings}
                onClick={handleShowOrgs}
                disabled={!groupName.trim() || !groupAbbrev.trim()}
              >
                Next
              </button>
            </div>
          </>
        ) : (
          <>
            <SearchOrgs
              clinicId={clinicid}
              onOrgsSelected={setSelectedOrgs}
              initialSelectedOrgs={selectedOrgs}
            />
            <div className={css.buttonContainer}>
              <button
                className={css.cancelSettings}
                onClick={() => setStep("settings")}
              >
                Back
              </button>
              <button className={css.saveSettings} onClick={handleSaveSettings}>
                Save
              </button>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default AddGroupContentModal

const transformSettingsForGroup = (
  settings: { setting_type: string; setting_text: string }[]
) => {
  return settings.reduce(
    (acc: Record<string, string>, setting) => {
      const value = setting.setting_text === "NaN" ? "0" : setting.setting_text

      if (setting.setting_type === "timezone") {
        acc["timezone"] = value
      } else if (setting.setting_type === "daylight_savings") {
        acc["daylight_savings"] = value === "1" ? "1" : "0"
      } else {
        acc[setting.setting_type] = value === "1" ? "1" : "0"
      }
      return acc
    },
    { calendar_v2: "1" }
  )
}
